import axios, { AxiosResponse } from 'axios';
import { config } from '../config';

const AuthEndpoint = `${config.backendUrl}/auth`

async function post<T = any>(url: string, data: object): Promise<AxiosResponse<T>> {
  return axios.post(url, data, { withCredentials: true });
}

export function authenticate(username: string, password: string) {
  return post(`${AuthEndpoint}/login`, {username, password});
}

export function logoutCurrentUser() {
  return post(`${AuthEndpoint}/logout`,{});
}
