import React, { useEffect, useState } from 'react';
import './ViewChats.css';
import { exportChats, getChatMessages, getUserChats } from '../../services/ChatApi';
import Chat from '../../entities/Chat';
import ChatMessage from '../../entities/ChatMessage';
import DummyChatbot from './DummyChatbot';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { Oval } from 'react-loader-spinner';

const ViewChats = ({ aiAssistantId }: { aiAssistantId: string }) => {
    const [chats, setChats] = useState<Chat[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedChat, setSelectedChat] = useState<Chat | null>(null);
    const [chatMemory, setChatMemory] = useState<any[]>([]);
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
    const [startDate, setStartDate] = useState<string>(new Date(new Date().setHours(0, 0, 0, 0)).toISOString());
    const [endDate, setEndDate] = useState<string>(new Date(new Date(new Date().setDate(new Date().getDate() + 1)).setHours(23, 59, 59, 999)).toISOString());
    const [page, setPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [inputSearchKeyword, setInputSearchKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');

    useEffect(() => {
        setIsLoading(true)
        getUserChats(aiAssistantId, startDate, endDate, page, 10, searchKeyword).then((response) => {
            setChats(response.data.content);
            setTotalPages(response.data.totalPages);
            console.log(response.data.content);
            setIsLoading(false)
        });
    }, [startDate, endDate, page, searchKeyword]);

    useEffect(() => {
        parseChatMemory(chatMemory);
    }, [chatMemory]);


    const convertToDatePickerValueFormat = (date: string) => {
        const utcDate = new Date(date);
        const res = utcDate.toISOString().split('T')[0]; // This ensures yyyy-MM-dd format
        return res;
    }
    const handleDateChange = (
        date: Date | null, // The selected date from react-datepicker
        setDate: React.Dispatch<React.SetStateAction<string>>
    ) => {
        
        if (!date) {
        
            return; // Handle case when the date is null
        }

        // Adjust the time depending on whether it's the start or end date
        if (setDate === setStartDate) {
            date.setHours(0, 0, 0, 0); // Start of the day
        } else if (setDate === setEndDate) {
            date.setHours(23, 59, 59, 999); // End of the day
        }
    
        // Convert the date to UTC and format it as ISO string
        const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        setDate(utcDate.toISOString()); // Set the date in ISO string format
    };
    

    const handleChatClick = (chat: Chat) => {
        getChatMessages(chat.chatId).then((response) => {
            setChatMemory(response.data);
        });
        setSelectedChat(chat);
    };

    const handleNext = () => {
        setPage(page + 1);
    };

    const handlePrevious = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    const parseChatMemory = (chatMemory: any[]) => {
        let chatMessages: ChatMessage[] = [];
        chatMemory.forEach((message) => {
            if (message.messageType !== 'SYSTEM') {
                chatMessages.push({
                    message: message.messageText,
                    sender: message.messageType,
                    context: message.messageContext
                });
            }
        });
        setChatMessages(chatMessages);
    }

    const exportChatMessages = () => {
        exportChats(aiAssistantId, startDate, endDate);
    };

    return (
        <div className="view-chats">
            <div className="date-picker">
                <label>Start Date: </label>
                <DatePicker
                    selected={startDate ? new Date(startDate) : null}
                    onChange={(date) => handleDateChange(date, setStartDate)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                />
                <label>End Date: </label>
                <DatePicker
                    selected={endDate ? new Date(endDate) : null}
                    onChange={(date) => handleDateChange(date, setEndDate)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                />
            </div>
            <div>
                <input
                    type='text'
                    value={inputSearchKeyword}
                    onChange={(e) => setInputSearchKeyword(e.target.value)}
                    placeholder='Search in user messages' />
                <button onClick={() => setSearchKeyword(inputSearchKeyword)}>Search</button>
                <button onClick={() => {
                    setInputSearchKeyword("");
                    setSearchKeyword("");
                }} style={{backgroundColor: "red"}}>Reset search</button>
            </div>
            <div className='chat-layout'>
                <button onClick={exportChatMessages} className="export-button">Export</button>
                <div className="chat-list" style={{ border: "5px" }}>
                    <table>
                        <thead>
                            <tr>
                                <th>Chat ID</th>
                                <th>Creation Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isLoading ? 
                                <div style={{padding:'1rem'}}>
                                    <Oval
                                        visible={true}
                                        height="40"
                                        width="40"
                                        color="#4fa94d"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </div>
                                :
                                chats.map(chat => (
                                    <tr key={chat.chatId}>
                                        <td><button onClick={() => handleChatClick(chat)}>{chat.chatId}</button></td>
                                        <td>{new Date(chat.creationDate).toLocaleString('tr-TR', { hour12: false })}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div className='pagination-button-container'>
                        {(page > 0) && (<button onClick={handlePrevious}>Previous</button>)}
                        {(page < totalPages - 1) && (<button onClick={handleNext}>Next</button>)}
                    </div>
                </div>
                <div className="separator"></div>
                {selectedChat && (
                    <div className="chat-container">
                        <DummyChatbot messages={chatMessages} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ViewChats;
