import axios from "axios";
import { config } from "../config";
import { get, post } from "./AxiosWrapper";
import saveAs from "file-saver";

const ContextFileApiEndpoint = `${config.backendUrl}/context-files`;

export function downloadContextFile(fileId: string, fileName: string) {
    return axios.get(`${ContextFileApiEndpoint}/downloadcontextfile`, {
        responseType: 'blob',
        withCredentials: true,
        params: {
            fileId
        }
    }).then((response) => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        console.log(fileName,"assadsa");
        saveAs(blob, fileName);
    });
}

export function loadContextFile(contextFile: FormData, aiAssistantId: string, isEcommerce: boolean) {
    contextFile.append('aiAssistantId', aiAssistantId);
    contextFile.append('isEcommerce', isEcommerce.toString());
    return axios.post(`${ContextFileApiEndpoint}/uploadcontextfile`, contextFile, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

export function getContextFiles(aiAssistantId: string) {
    return get(`${ContextFileApiEndpoint}/getaiassistantcontextfiles`, { aiAssistantId });
}

export function deleteContextFile(aiAssistantId: string, fileId: string) {
    return post(`${ContextFileApiEndpoint}/deletecontextfile`, { aiAssistantId, fileId });
}

export function ingestContextFile(aiAssistantId: string, fileId: string) {
    return post(`${ContextFileApiEndpoint}/ingestcontextfile`, {aiAssistantId, fileId});
}

export function removeFileEmbedding(aiAssistantId: string, fileId: string) {
    return post(`${ContextFileApiEndpoint}/removefileembedding`, {aiAssistantId, fileId});
}