// DummyPanel.js or DummyPanel.tsx if using TypeScript
import React, { useContext, useRef } from 'react';
import './Webwidget.css'
import { AuthContext } from '../../services/authContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faS, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { config } from '../../config';
library.add(faS, faClipboard);
const WebWidget = ({aiAssistantId}: {aiAssistantId: string | undefined}) => {
    const scriptRef = useRef<HTMLTextAreaElement>(null);
    const iframeRef = useRef<HTMLTextAreaElement>(null);

    const { user } = useContext(AuthContext);

    const widgetScript = `<script async src="${config.backendUrl}/js/widgetloader.js" aiAssistantId="${aiAssistantId}"></script>`;
    const widgetIframe = `<iframe
src="https://dev.whatisreal.co/chatbot-widget-iframe?aiAssistantId=${aiAssistantId}" id="wir-assistant-chat-frame-${aiAssistantId}"
width="100%"
style="height: 100%; min-height: 700px"
frameborder="0"
></iframe>`

    const handleCopy = (typeCheck:Boolean) => {
        if (scriptRef.current && !typeCheck) {
            scriptRef.current.select();
            navigator.clipboard.writeText(scriptRef.current.value);
        } else if (iframeRef.current && typeCheck) {
            iframeRef.current.select();
            navigator.clipboard.writeText(iframeRef.current.value);
        }
    };
    return (
        <div>
            <div className='panel-container'>
                <p>Please include the script below in your website to use the chatbot.</p>

                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <textarea className='script-textarea'
                        ref={scriptRef}
                        value={widgetScript}
                        readOnly
                    />

                    <button className='copy-button' onClick={() => handleCopy(false)}>
                        <FontAwesomeIcon icon={["fas", "clipboard"]} className='copy-icon'/>
                    </button>
                </div>
            </div>
            <div className='panel-container'>
                <p>Iframe</p>

                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <textarea className='script-textarea'
                        ref={iframeRef}
                        value={widgetIframe}
                        readOnly
                    />

                    <button className='copy-button' onClick={() => handleCopy(true)}>
                        <FontAwesomeIcon icon={["fas", "clipboard"]} className='copy-icon'/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WebWidget;
