import React, { useState, useRef } from 'react';
import './Settings.css';
import DummyPanel from './SettingComponents/DummyPanel';
import General from './SettingComponents/General';
import ChatWidget from './SettingComponents/ChatWidget';
import ModelEdit from './SettingComponents/ModelEdit';

import Security from './SettingComponents/Security';
import Notifications from './SettingComponents/Notifications';

export interface ColorSchema {
  backgroundColor: string;
  // userChatBubbleColor: string;
  // userChatTextColor: string;
  // aiChatBubbleColor: string;
  // aiChatTextColor: string;
}

export interface ChatWidgetProps {
  colorSchema: ColorSchema;
  sampleMessages: string[];
}

const Settings = ({aiAssistantId}: {aiAssistantId?: string}) => {
  const [activePanel, setActivePanel] = useState('');

  const tabs = ['General', 'Model', 'Chat Widget', 'Security', 'Notifications'];

  
  if (!aiAssistantId) {
    return <div>No AI Assistant ID provided</div>;
  }
  return (
    <div className="settings-container">
      <div className="sidebar">
        <ul>
          {tabs.map((tab) => (
            <li key={tab} onClick={() => setActivePanel(tab)}>
              {tab}
            </li>
          ))}
        </ul>
      </div>
      <div className="content">
        {activePanel === 'General' && <General aiAssistantId={aiAssistantId}/>}
        {activePanel === 'Model' && <ModelEdit aiAssistantId={aiAssistantId}/>}
        {activePanel === 'Chat Widget' && <ChatWidget aiAssistantId={aiAssistantId}/>}
        {activePanel === 'Security' && <Security aiAssistantId={aiAssistantId}/>}
        {activePanel === 'Notifications' && <Notifications aiAssistantId={aiAssistantId}/>}
        </div>
    </div>
  );
};

export default Settings;