// src/components/PrivateRoute.tsx
import React, { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../services/authContext';
import "./App.css";

// Define the types for props
interface PrivateRouteProps {
  children: React.ReactNode; // Use React.ReactNode for children
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { user, loading, getCurrentAuthenticatedUser } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    getCurrentAuthenticatedUser();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Or some other loading indicator
  }

  if (!user) {
    // Redirect to login if there is no user
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <>{children}</>; // Render children when user is authenticated
};

export default PrivateRoute;
