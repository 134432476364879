// src/pages/Login.tsx
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './ChangePassword.css'
import { AuthContext } from '../services/authContext';
import { changeUserPassword } from '../services/UserApi';

const ChangePassword = () => {

    useEffect(() => {
        // Apply styles to body on component mount
        document.body.style.display = 'flex';
        document.body.style.justifyContent = 'center';
        document.body.style.alignItems = 'center';

        // Revert styles on component unmount
        return () => {
            document.body.style.display = '';
            document.body.style.justifyContent = '';
            document.body.style.alignItems = '';
        };
    }, []);

    const navigate = useNavigate();
    const [currPassword, setCurrPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');

    const { user } = useContext(AuthContext);

    const handlePasswordChange = (e: React.FormEvent) => {
        e.preventDefault();
        if (newPassword !== repeatNewPassword) {
            alert('New passwords do not match');
            return;
        }
        changeUserPassword(currPassword, newPassword).then((response) => {
            alert('Password changed successfully');
            navigate('/main');
        }).catch((error) => {
            alert('Password change failed, current password is wrong');
        });

    };

    return (
        <div className="password-change-container">
            <form onSubmit={handlePasswordChange}>
                <div className="form-group">
                    <label>Current password:</label>
                    <input type="password" value={currPassword} onChange={(e) => setCurrPassword(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>New password:</label>
                    <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>New password (again):</label>
                    <input type="password" value={repeatNewPassword} onChange={(e) => setRepeatNewPassword(e.target.value)} />
                </div>
                <button type="submit">Change Password</button>
                <button type="button" onClick={() => navigate('/main')}>Cancel</button>
            </form>
        </div>

    );
};

export default ChangePassword;
