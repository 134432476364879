// authContext.tsx
import React, { useState, useEffect } from 'react';
import { getCurrentUser } from './UserApi';
import User from '../entities/User';
import AIAssistant from '../entities/AIAssistant';
import { authenticate, logoutCurrentUser } from './AuthService';
import { getAIAssistantFromId } from './AIAssistantApi';



export const AuthContext = React.createContext<{
  user: User | null;
  loading: boolean;
  login: (username: string, password: string) => void;
  logout: () => void;
  getCurrentAuthenticatedUser: () => void;
  getUserAIAssistantWithId: (id: string) => Promise<AIAssistant>;
}>({
  user: null,
  loading: true,
  login: () => { },
  logout: () => { },
  getCurrentAuthenticatedUser: () => { },
  getUserAIAssistantWithId: async () => new AIAssistant(),
});
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const login = async (username: string, password: string) => {
    // Implement login functionality here
    authenticate(username, password).then(response => {
      // console.log(response, "ok");
      // if (response.data.role === 'ROLE_USER') {
      // }
      getCurrentAuthenticatedUser();

    }).catch((error) => {
      console.error('Login failed', error);
    });
  };

  const logout = () => {
    logoutCurrentUser().then(() => {
      setUser(null);
    }
    ).catch((error) => {
      console.error('Logout failed', error);
    });
  };

  const getCurrentAuthenticatedUser = async () => {
    getCurrentUser().then((response) => {
      setUser({
        username: response.data.username,
        aiAssistantIds: response.data.aiAssistantIds,
        email: response.data.email,
        role: response.data.role,
        id: response.data.id
      });
      setLoading(false);
    }).catch((error) => {
      console.error('Get current user failed:', error);
      setLoading(false);
    });
  };

  const getUserAIAssistantWithId = async (id: string): Promise<AIAssistant> => {
    let aiAssistant: AIAssistant = new AIAssistant();
    await getAIAssistantFromId(id).then((response) => {
      aiAssistant = {
        id: response.data.id,
        instructions: response.data.instructions,
        aiAssistantName: response.data.aiAssistantName,
        widgetName: response.data.widgetName,
        firstMessage: response.data.firstMessage,
        firstMessageEnabled: response.data.firstMessageEnabled,
        availableModelNames: response.data.availableModelNames,
        selectedModelName: response.data.selectedModelName,
        temperature: response.data.temperature,
        rateLimit: response.data.rateLimit,
        minScore: response.data.minScore,
        maxResults: response.data.maxResults,
        fineTuneData: response.data.fineTuneData.map((qaPair: any) => ({ question: qaPair.question, answer: qaPair.answer })),
        reportKeywords: response.data.reportKeywords,
        avatarUrl: response.data.widgetAvatarUrl,
        isActive: response.data.isActive
      };
    });
    return aiAssistant;
  }

  return (
    <AuthContext.Provider value={{ user, loading, login, logout, getCurrentAuthenticatedUser, getUserAIAssistantWithId }}>
      {children}
    </AuthContext.Provider>
  );
};