import React, { useContext, useEffect, useState } from 'react';
import './General.css'; // Assuming you've created a specific CSS file for this component
import { AuthContext } from '../../services/authContext';
import { setAiAssistantName } from '../../services/AIAssistantApi';

const General = ({aiAssistantId}: {aiAssistantId: string}) => {
  const { user, getCurrentAuthenticatedUser, getUserAIAssistantWithId } = useContext(AuthContext);
  const [botName, setBotName] = useState(''); // Default bot name


  useEffect(() => {
    const fetchAIAssistant = async () => {
      const aiAssistant = await getUserAIAssistantWithId(aiAssistantId);
      setBotName(aiAssistant.aiAssistantName || aiAssistantId);
    };

    fetchAIAssistant();
  }, [aiAssistantId, getUserAIAssistantWithId]);

  const saveBotName = () => {
    // Handle the save operation here
    setAiAssistantName(aiAssistantId, botName).then((response) => {
      // console.log(response);
      getCurrentAuthenticatedUser(); // Update the user context after saving the name
      alert('Bot name saved');
    }).catch((error) => {
      console.error('Error saving bot name', error);
    });
  };

  return (
    <div className="panel-container">
      <h2>General</h2>
      <div className="setting-item">
        <h3>Chatbot ID</h3>
        <p className="setting-detail">{aiAssistantId}</p>
      </div>
      
      {/* Dropdown for AI Model Selection */}
      
      <div className="setting-item">
        <h3>Name</h3>
        <input 
          type="text" 
          value={botName} 
          onChange={(e) => setBotName(e.target.value)} 
          className="setting-input"
        />
        <button onClick={saveBotName} className="save-button">Save</button>
      </div>
    </div>
  );
};

export default General;
