import axios from "axios";
import { config } from "../config";
import { get, post } from "./AxiosWrapper";
import saveAs from "file-saver";

const ChatsApiEndpoint = `${config.backendUrl}/chats`;

export function updateAiAssistantChatbot(aiAssistantId: string, temperature: number, selectedModelName: string) {
    return post(`${ChatsApiEndpoint}/updateaiassistantchatbot`, { aiAssistantId, temperature, selectedModelName });
}

export function getNewChat(aiAssistantId: string) {
    return post(`${ChatsApiEndpoint}/newchat`, { aiAssistantId });
}

export function sendDebugMessage(chatId: string, message: string) {
    message = message.replaceAll("[\"']", "");
    return post(`${ChatsApiEndpoint}/debugchat`, { chatId, message });
}

export function sendMessage(chatId: string, message: string) {
    const sanitizedMessage = message.replaceAll("[\"']", "");
    return post(`${ChatsApiEndpoint}/chat`, { chatId, sanitizedMessage });
}

export function getChatMessages(chatId: string) {
    return get(`${ChatsApiEndpoint}/getchat`, { chatId });
}

export function getUserChats(aiAssistantId: string,
    startDate: string, endDate: string, page: number, size: number, keyword: string) {
    return get(`${ChatsApiEndpoint}/listchats`, {
        aiAssistantId,
        startDate,
        endDate,
        page,
        size,
        keyword
    });
}

export function exportChats(aiAssistantId: string, startDate: string, endDate: string) {
    axios.get(`${ChatsApiEndpoint}/exportchats`, {
        params: {
            aiAssistantId,
            startDate,
            endDate
        },
        responseType: 'blob',
        withCredentials: true
    }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/json' });
        saveAs(blob, 'chats.jsonl');
    });
}

export function getChatReport(aiAssistantId: string, startDate: string, endDate: string) {
    return get(`${ChatsApiEndpoint}/generatereport`, { aiAssistantId, startDate, endDate });
}