import QAPair from "./QAPair";

class AIAssistant {
    id: string;
    instructions: string;
    aiAssistantName?: string;
    widgetName?: string;
    firstMessage?: string;
    firstMessageEnabled: boolean;
    availableModelNames: string[];
    selectedModelName: string;
    temperature: number;
    rateLimit: number;
    minScore: number;
    maxResults: number;
    fineTuneData: QAPair[];
    reportKeywords: string[];
    avatarUrl: string;
    isActive: boolean;

    constructor() {
        this.id = '';
        this.instructions = '';
        this.aiAssistantName = '';
        this.widgetName = '';
        this.firstMessage = 'empty first message';
        this.firstMessageEnabled = true;
        this.availableModelNames = [];
        this.selectedModelName = '';
        this.temperature = 0;
        this.rateLimit = 0;
        this.minScore = 0.5;
        this.maxResults = 5;
        this.fineTuneData = [];
        this.reportKeywords = [];
        this.avatarUrl = "";
        this.isActive = false;
    }
}

export default AIAssistant;