import axios from "axios";
import { config } from "../config";
import QAPair from "../entities/QAPair";
import { get, post, postWithReqParam } from "./AxiosWrapper";

const AIAssistantApiEndpoint = `${config.backendUrl}/aiassistant`;

export function getAIAssistantFromId(aiAssistantId: string) {
    return get(`${AIAssistantApiEndpoint}/getaiassistantfromid`, { aiAssistantId });
}

export function setAiAssistantInstructions(aiAssistantId: string, instructions: string) {
    return post(`${AIAssistantApiEndpoint}/setassistantinstruction`, { aiAssistantId, instructions });
}

export function setAiAssistantName(aiAssistantId: string, name: string) {
    return post(`${AIAssistantApiEndpoint}/setaiassistantname`, { aiAssistantId, name });
}

export function setAiAssistantRateLimit(aiAssistantId: string, rateLimit: number) {
    return post(`${AIAssistantApiEndpoint}/setaiassistantratelimit`, { aiAssistantId, rateLimit });
}

export function updateAiAssistantFineTuneData(aiAssistantId: string, fineTuneData: QAPair[]) {
    return post(`${AIAssistantApiEndpoint}/updatefinetunedata`, { aiAssistantId, fineTuneData });
}

export function removeAiAssistantQAPair(aiAssistantId: string, question: string, answer: string) {
    return post(`${AIAssistantApiEndpoint}/removeqapair`, { aiAssistantId, question, answer });
}

export function fineTuneAiAssistantModel(aiAssistantId: string, modelName: string) {
    return post(`${AIAssistantApiEndpoint}/finetunemodel`, { aiAssistantId, modelName });
}

export function getAllowedOrigins(aiAssistantId: string) {
    return get(`${AIAssistantApiEndpoint}/get-allowed-origins`, {aiAssistantId});
}

export function addAllowedOrigin(aiAssistantId: string, origin: string) {
    return post(`${AIAssistantApiEndpoint}/add-allowed-origin`, { origin, aiAssistantId });
}

export function deleteAllowedOrigin(aiAssistantId: string, origin: string) {
    return post(`${AIAssistantApiEndpoint}/remove-allowed-origin`, { origin, aiAssistantId });
}

export function setAiAssistantWidgetConfig(aiAssistantId: string, widgetConfig: any) {
    return post(`${AIAssistantApiEndpoint}/setaiassistantwidgetconfig`, { aiAssistantId, widgetConfig });
}

export function setAiAssistantWidgetLocation(aiAssistantId: string, widgetLocation: any) {
    return post(`${AIAssistantApiEndpoint}/setaiassistantwidgetlocation`, { aiAssistantId, widgetLocation });
}

export function setAiAssistantSampleQuestions(aiAssistantId: string, sampleQuestions: string[]) {
    return post(`${AIAssistantApiEndpoint}/setaiassistantsamplequestions`, { aiAssistantId, sampleQuestions });
}

export function setAiAssistantWidgetName(aiAssistantId: string, widgetName: string) {
    return post(`${AIAssistantApiEndpoint}/setaiassistantwidgetname`, { aiAssistantId, widgetName });
}

export function setAiAssistantFirstMessage(aiAssistantId: string, firstMessage: string) {
    return post(`${AIAssistantApiEndpoint}/setfirstmessage`, { aiAssistantId, firstMessage });
}

export function setAiAssistantFirstMessageEnabled(aiAssistantId: string, firstMessageEnabled: Boolean) {
    return post(`${AIAssistantApiEndpoint}/setfirstmessageenabled`, { aiAssistantId, firstMessageEnabled });
}

export function setAiAssistantStatus(aiAssistantId: string, status: boolean) {
    return post(`${AIAssistantApiEndpoint}/set-aiassistant-status`, { aiAssistantId, status });
}

export function updateAiAssistantContentRetriever(aiAssistantId: string, minScore: number, maxResults: number) {
    return post(`${AIAssistantApiEndpoint}/updatecontentretriever`, { aiAssistantId, minScore, maxResults });
}

export function addReportKeyword(aiAssistantId: string, reportKeyword: string) {
    return postWithReqParam(`${AIAssistantApiEndpoint}/addreportkeyword`, { aiAssistantId, reportKeyword });
}

export function removeReportKeyword(aiAssistantId: string, reportKeyword: string) {
    return postWithReqParam(`${AIAssistantApiEndpoint}/removereportkeyword`, { aiAssistantId, reportKeyword });
}

export function setAvatarURL(aiAssistantId: string, avatarUrl: string) {
    return post(`${AIAssistantApiEndpoint}/setavatarurl`, { aiAssistantId, avatarUrl })
}