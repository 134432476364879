// src/pages/Main.tsx
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import chatbotImage from '../assets/dummy.jpeg';
import './Main.css'
import { AuthContext } from '../services/authContext';
import { createNewAIAssistant, deleteUserAIAssistant, getUserAIAssistantInfo } from '../services/UserApi';
import {ToastContainer, toast} from "react-toastify";

interface AIAssistantInfo {
  aiAssistantId: string,
  aiAssistantName: string
}

const Main = () => {
  const navigate = useNavigate();
  const { user, getCurrentAuthenticatedUser, logout } = useContext(AuthContext);
  const [aiAssistants, setAiAssistants] = useState<AIAssistantInfo[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);


  useEffect(() => {
    if (!user) {
      getCurrentAuthenticatedUser();
    }
    getUserAIAssistantInfo().then(res => {
      setAiAssistants(res.data);
    }).catch(err => {
      console.error(err);
    })
  }, [user, getCurrentAuthenticatedUser]);


  useEffect(() =>{}, [isDisabled]);

  const navigateToModel = (modelName: string) => {
    navigate(`/main/${modelName}`); // Navigate with the model name
  };

  const navigateToChangePassword = () => {
    navigate('/changepassword'); // Navigate to change password page
  }

  const handleLogout = () => {
    logout();
    navigate('/login');
  }

  const handleAddNewAIAssistant = () => {
    setIsDisabled(true);
    createNewAIAssistant().then(res => {
      if (res.status === 200){
        toast.success("Assistant created", { position: 'bottom-right' })
      } else {
        toast.error("Error occured", { position: 'bottom-right' })
      }
      setIsDisabled(false);
      const newAIAssistant = {
        aiAssistantId: res.data.aiAssistantId,
        aiAssistantName: res.data.aiAssistantName
      }
      setAiAssistants(aiAssistants => [...aiAssistants, newAIAssistant]);
    }).catch(err => {
      console.error(err);
    })
  }

  const handleDeleteUserAIAssistant = (aiAssistantId: string) => {
    setIsDisabled(true);
    deleteUserAIAssistant(aiAssistantId).then(res => {
      if (res.status === 200){
        toast.success("Assistant deleted", { position: 'bottom-right' })
      } else {
        toast.error("Error occured", { position: 'bottom-right' })
      }
      setIsDisabled(false);
      setAiAssistants(aiAssistants => aiAssistants.
        filter(aiAssistant => aiAssistant.aiAssistantId !== aiAssistantId));
    }).catch(err => {
      console.error(err);
    })
  }

  return (
    <>
      <div className="main-container" >
        <div className="sidebar">
          <h2>Welcome {user?.username}</h2>
          <button onClick={() => navigateToChangePassword()}>Change Password</button>
          {user && user.role === 'ROLE_ADMIN' && <button onClick={() => navigate('/admin')}>Admin Page</button>}
          <button onClick={() => handleAddNewAIAssistant()} disabled={isDisabled}>Create new AIAssistant</button>

          <button className="logout-button" onClick={() => {
            if (window.confirm('Are you sure you want to logout?')) {
              handleLogout();
            }
          }}
          >Logout</button>
        </div>
        <div style={{marginLeft:"1.5rem", width:"80%"}}>
          <h2>Your assistants:</h2>
          <div className="content" style={{ display: "flex"}}>
              {user ? (
              aiAssistants.length > 0 ? (
                aiAssistants.map((aiAssistant, index) => (
                  <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button className="model-button" onClick={() => navigateToModel(aiAssistant.aiAssistantId)}>
                      <img src={chatbotImage} alt={aiAssistant.aiAssistantId} />
                      <div className="text">{aiAssistant.aiAssistantName !== null ? aiAssistant.aiAssistantName : aiAssistant.aiAssistantId}</div>
                    </button>
                    <button
                      disabled={isDisabled}
                      style={{ backgroundColor: !isDisabled ? "red" : "#c0c0c0", width: '150px' }}
                      onClick={ async () => {
                        if (window.confirm("Are you sure you want to delete AIAssistant? This action is IRREVERSIBLE!")) {
                          handleDeleteUserAIAssistant(aiAssistant.aiAssistantId);
                        }
                      }}>
                      Delete
                    </button>
                  </div>
                ))
              ) : (
                <div>Loading...</div> // Replace with your actual loading circle component or code
              )
            ) : (
              <div>Please log in to see your AI Assistants.</div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default Main;