import { config } from "../config";
import { get, post } from "./AxiosWrapper";

const UserApiEndpoint = `${config.backendUrl}/user`;


export async function getCurrentUser() {
    return get(`${UserApiEndpoint}/getcurrentuser`, {});
}

export function getUserAIAssistantInfo() {
    return get(`${UserApiEndpoint}/getuseraiassistants`, {})
}

export function createNewAIAssistant() {
    return post(`${UserApiEndpoint}/createnewaiassistant`, {});
}

export function deleteUserAIAssistant(aiAssistantId: string) {
    return post(`${UserApiEndpoint}/deleteaiassistant`, {aiAssistantId});
}

export async function changeUserPassword(currPassword: string, newPassword: string) {
    return post(`${UserApiEndpoint}/changepassword`, { currPassword, newPassword });
}
