import React, { useState } from 'react';
import './Reports.css';
import DummyPanel from './SettingComponents/DummyPanel';
import ViewChats from './ReportingComponents/ViewChats';
import ViewStatistics from './ReportingComponents/ViewStatistics';



const Reports = ({aiAssistantId}: {aiAssistantId: string }) => {
  const [activePanel, setActivePanel] = useState('');

  const tabs = ['Chats', 'Statistics'];

  

  return (
    <div className="settings-container">
      <div className="sidebar">
        <ul>
          {tabs.map((tab) => (
            <li key={tab} onClick={() => setActivePanel(tab)}>
              {tab}
            </li>
          ))}
        </ul>
      </div>
      <div className="content">
        {activePanel === 'Chats' && <ViewChats aiAssistantId={aiAssistantId} />}
        {activePanel === 'Statistics' && <ViewStatistics aiAssistantId={aiAssistantId} />}
        </div>
    </div>
  );
};

export default Reports;