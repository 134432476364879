import React, { useState, useRef, useContext, useEffect } from 'react';
import { fineTuneAiAssistantModel, removeAiAssistantQAPair, setAiAssistantInstructions, updateAiAssistantFineTuneData } from '../../services/AIAssistantApi';

import './ModelEdit.css'; // Ensure the CSS file is imported
import { AuthContext } from '../../services/authContext';
import QAPair from '../../entities/QAPair';
import { deleteContextFile, downloadContextFile, getContextFiles, ingestContextFile, loadContextFile, removeFileEmbedding } from '../../services/ContextFileApi';


interface FileInfo {
    fileName: string,
    fileId: string,
    isFileGivenAsContext: boolean
}

const ModelEdit = ({ aiAssistantId }: { aiAssistantId: string }) => {
    const { user, getCurrentAuthenticatedUser, getUserAIAssistantWithId } = useContext(AuthContext);
    const [instructions, setInstructions] = useState('');

    const fileInput = useRef<HTMLInputElement | null>(null); // Create a ref for the file input
    const [isLoading, setIsLoading] = useState(false); // New state for loading status
    const [isSuccess, setIsSuccess] = useState(false);
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [qaPairs, setQAPairs] = useState<QAPair[]>([]);
    const [selectedModel, setSelectedModel] = useState(''); // Initialize with default value
    const [contextFiles, setContextFiles] = useState<FileInfo[]>([]);
    const [selectedCategory, setSelectedCategory] = useState('e-commerce');
    const [isExceeded, setIsExceeded] = useState(false);

    useEffect(() => {
        const fetchAIAssistant = async () => {
            const aiAssistant = await getUserAIAssistantWithId(aiAssistantId);
            setInstructions(aiAssistant.instructions);
            setIsExceeded(aiAssistant.instructions.length > 5000 ? true : false);
            setQAPairs(aiAssistant.fineTuneData); // Set the Q&A pairs from the AI Assistant data
            setSelectedModel(aiAssistant.selectedModelName);
        };
        getContextFiles(aiAssistantId).then(response => {
            setContextFiles(response.data);
        }).catch(error => {
            console.error(error);
        })
        fetchAIAssistant();
    }, [aiAssistantId, getUserAIAssistantWithId]);

    useEffect(() => {

    }, [isExceeded])

    const handleFileUpload = async (event: any) => {
        event.preventDefault();
        const fileInput = event.target.elements.fileInput;
        const dropdown = event.target.elements.categoryDropdown;

        if (fileInput.files.length > 0) {
            setIsLoading(true); // Start loading
            setIsSuccess(false); // Reset success status
            const file = fileInput.files[0];
            const formData = new FormData();
            formData.append('contextfile', file);
            // formData.append('category', dropdown.value);
            const isEcommerce = dropdown.value === "e-commerce";
            console.log(isEcommerce);

            try {
                const response = await loadContextFile(formData, aiAssistantId, isEcommerce);
                setIsSuccess(true); // Set success status if upload is successful
                const newContextFile = {
                    fileName: response.data.fileName,
                    fileId: response.data.fileId,
                    isFileGivenAsContext: response.data.isFileGivenAsContext,
                    category: dropdown.value
                };
                setContextFiles(contextFiles => [...contextFiles, newContextFile]);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false); // End loading regardless of success or failure
            }
        }
    };

    const handleContextFileDelete = (fileId: string) => {
        deleteContextFile(aiAssistantId, fileId).then(response => {
            setContextFiles(contextFiles => contextFiles.filter(file => file.fileId !== fileId));
        }).catch(err => {
            console.error(err);
        })
    };

    const handleAddingFileAsContext = (fileId: string) => {
        ingestContextFile(aiAssistantId, fileId).then(res => {
            setContextFiles(contextFiles => contextFiles.map(file => {
                return file.fileId === fileId ? { ...file, isFileGivenAsContext: true } : file
            }));
        });
    }

    const handleRemovingFileContext = (fileId: string) => {
        removeFileEmbedding(aiAssistantId, fileId).then(res => {
            setContextFiles(contextFiles => contextFiles.map(file => {
                return file.fileId === fileId ? { ...file, isFileGivenAsContext: false } : file
            }));
        });
    }

    const handleInstructionsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInstructions(event.target.value);
        setIsExceeded(event.target.value.length > 5000 ? true : false)
    };

    const handleInstructionsSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (user && aiAssistantId) {
            if (instructions.length > 5000) {
                alert('Instructions exceeds limit');
                return 
            }

            setAiAssistantInstructions(aiAssistantId, instructions).then((response) => {
                setInstructions(response.data.instructions);
                getCurrentAuthenticatedUser(); // Update the user context after saving instructions
                alert('Instructions saved');
            });
        };
    };

    const handleSave = () => {
        if (selectedModel === "gpt-4") {
            alert("GPT 4 fine tuning is not supported.");
            return;
        }
        fineTuneAiAssistantModel(aiAssistantId, selectedModel).then((response) => {
            getCurrentAuthenticatedUser(); // Update the user context after saving the model
        }
        ).catch((error) => {
            console.error('Error saving model', error);
        });
    }

    const handleSubmit = () => {

        // Your existing logic to add the question and answer
        const newQAPair = { question: question, answer: answer };
        setQAPairs(qaPairs => [...qaPairs, newQAPair]);
        /// should send request to backend to update q&as
        updateAiAssistantFineTuneData(aiAssistantId, [newQAPair]).then(response => {
            // console.log(response);
        });
        // Clear the input fields after submission
        setQuestion('');
        setAnswer('');
    };

    const handleRemove = (pairToRemove: QAPair) => {
        // Remove the pair from the local state
        setQAPairs(qaPairs => qaPairs.filter(pair => pair !== pairToRemove));

        // Send request to backend to remove the Q&A pair
        removeAiAssistantQAPair(aiAssistantId, pairToRemove.question, pairToRemove.answer).then(response => {
        });
    };


    return (
        <div className="panel-container">
            <div className="model-edit-container">
                <div className="model-edit-section">
                    <div style={{ marginBottom: '20px' }}>
                        <form onSubmit={handleFileUpload}>
                            <label htmlFor="file-upload">Upload File:</label>
                            <input type="file" name="fileInput" id="file-upload" />

                            <label htmlFor="category-dropdown">Category:</label>
                            <select id="category-dropdown" name="categoryDropdown" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                                <option value="e-commerce">E-commerce</option>
                                <option value="information">Information</option>
                            </select>

                            <button type="submit">Submit</button>

                            <div className="status-icon">
                                {isLoading && <div className="loader"></div>} {/* Render loading icon when loading */}
                                {isSuccess && <span style={{ color: 'green' }}>✔</span>} {/* Render success message when upload is successful */}
                            </div>
                        </form>
                        <div>Context Files:</div>
                        {contextFiles.map((file, index) => (
                            <React.Fragment key={index}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{file.fileName}</div>
                                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: "nowrap" }}>
                                        <button onClick={() => downloadContextFile(file.fileId, file.fileName)} style={{ marginRight: '10px' }}>Download File</button>
                                        {!file.isFileGivenAsContext && <button onClick={() => handleAddingFileAsContext(file.fileId)}
                                            style={{ marginRight: '10px', backgroundColor: 'purple' }}>Add To Model</button>}
                                        {file.isFileGivenAsContext && <button onClick={() => handleRemovingFileContext(file.fileId)}
                                            style={{ marginRight: '10px', backgroundColor: 'orange' }}>Remove From Model</button>}

                                        <button disabled={file.isFileGivenAsContext} onClick={() => handleContextFileDelete(file.fileId)}
                                            className={file.isFileGivenAsContext ? 'delete-context-file-disabled' : 'delete-context-file-enabled'}
                                        >Remove File</button>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>

                    <form onSubmit={handleInstructionsSubmit}>
                        <div>
                            <div>Your AI Assistant ID: {aiAssistantId}</div>
                            <label htmlFor="model-instructions">Model Instructions:</label>
                            <textarea
                                id="model-instructions"
                                className="model-edit-input model-instructions"
                                value={instructions}
                                onChange={handleInstructionsChange}>
                            </textarea>
                            {/* Save Button right after Model Instructions */}

                            <div style={{ marginBottom: '20px' }}>
                            <p style={{color: isExceeded ? "red" : "white" }}>Character count (max. 5000): {instructions.length}</p>
                                <button type="submit" className="save-button">Save</button>
                            </div>
                        </div>
                    </form>
                    <div>
                        <input
                            type="text"
                            placeholder="Enter Question"
                            className="model-edit-input"
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Enter Answer"
                            className="model-edit-input"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                        />
                        <button onClick={handleSubmit} className="submit-button">Add Q&A</button>
                        <button onClick={handleSave} className="save-button">Update Model</button>
                    </div>
                    {/* Render Submitted Q&A Pairs in a chat-like format */}
                    <div className="qa-container">
                        {qaPairs.map((pair, index) => (
                            <React.Fragment key={index}>
                                <div className="qa-question">Q: {pair.question}</div>
                                <div className="qa-answer">A: {pair.answer}</div>
                                <button onClick={() => handleRemove(pair)} className="remove-button">Remove</button>
                            </React.Fragment>
                        ))}
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ModelEdit;
