// src/pages/MainApp.tsx
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './MainApp.css'; // Make sure the CSS file is correctly imported
import Chatbot from '../components/Chatbot';
import Settings from '../components/Settings';
import Integration from '../components/Integration';
import Reports from '../components/Reports';

const MainApp = () => {
  const [activeComponent, setActiveComponent] = useState('Chatbot');
  const { aiAssistantId } = useParams<{ aiAssistantId: string }>();

  const navigate = useNavigate();

  const renderComponent = () => {
    if (aiAssistantId) {
      switch (activeComponent) {
        case 'Chatbot':
          return <Chatbot aiAssistantId={aiAssistantId} />
        case 'Settings':
          return <Settings aiAssistantId={aiAssistantId} />;
        case 'Integration':
          return <Integration aiAssistantId={aiAssistantId} />;
        case 'Reports':
          return <Reports aiAssistantId={aiAssistantId} />;
        default:
          return <div>Select a component</div>;
      }
    }
    
  };

  return (
    <div className="main-app-container">
      <div className="top-nav">
        <button onClick={() => setActiveComponent('Chatbot')}>Chatbot</button>
        <button onClick={() => setActiveComponent('Settings')}>Settings</button>
        <button onClick={() => setActiveComponent('Integration')}>Integration</button>
        <button onClick={() => setActiveComponent('Reports')}>Reports</button>
        <button onClick={() => navigate('/main')}>Back to main page</button>
      </div>
      <div className="main-content">
        {renderComponent()}
      </div>
    </div>
  );
};

export default MainApp;