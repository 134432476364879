import React, { useContext, useState, useEffect } from 'react';
import { addAllowedOrigin, getAllowedOrigins, setAiAssistantRateLimit, deleteAllowedOrigin } from '../../services/AIAssistantApi';
import { AuthContext } from '../../services/authContext';
import './Security.css';

const Security = ({aiAssistantId}: {aiAssistantId: string}) => {
    const { user, getCurrentAuthenticatedUser, getUserAIAssistantWithId } = useContext(AuthContext);
    const [origin, setOrigin] = useState('');
    const [rateLimit, setRateLimit] = useState(0);
    const [allowedOrigins, setAllowedOrigins] = useState<string[]>([]);

    useEffect(() => {
        getAllowedOrigins(aiAssistantId).then((response) => {
            setAllowedOrigins(response.data);
        });
    }, [aiAssistantId]);

    useEffect(() => {
        const fetchAIAssistant = async () => {
            const aiAssistant = await getUserAIAssistantWithId(aiAssistantId);
            setRateLimit(aiAssistant.rateLimit);
        };

        fetchAIAssistant();
    }, [aiAssistantId, getUserAIAssistantWithId]);

    const handleAddOrigin = () => {
        if (!aiAssistantId) {
            return;
        }
        addAllowedOrigin(aiAssistantId, origin).then((response) => {
            setAllowedOrigins(prevOrigins => [...prevOrigins, origin]);
            alert('Origin added');
            setOrigin('');
        });
    };

    const handleDeleteOrigin = (originToDelete: string) => {
        if (!aiAssistantId) {
            return;
        }
        deleteAllowedOrigin(aiAssistantId, originToDelete).then((response) => {
            setAllowedOrigins(prevOrigins => prevOrigins.filter(origin => origin !== originToDelete));
        });
    };

    const handleSaveLimit = () => {
        if (!aiAssistantId) {
            return;
        }
        setAiAssistantRateLimit(aiAssistantId, rateLimit).then((response) => {
            console.log(response.data);
            alert('Rate limit saved');
        });

    };

    return (
        <div className="panel-container">
            <h2>Security</h2>
            <div className="setting-item">
                <h3>Allowed Origins</h3>
                {allowedOrigins.map((origin, index) => (
                    <div key={index} className="setting-detail">
                        <div className='origin-container'>
                        <p>{origin}</p>
                        <button onClick={() => handleDeleteOrigin(origin)} className="delete-button">Delete</button>
                        </div>
                    </div>
                ))}
                <input
                    type="text"
                    value={origin}
                    onChange={(e) => setOrigin(e.target.value)}
                    className="setting-input"
                />
                <button onClick={handleAddOrigin} className="save-button">Add Origin</button>
            </div>
            <div className="setting-item">
                <h3>Rate Limit</h3>
                <input
                    type="text"
                    value={rateLimit}
                    onChange={(e) => setRateLimit(Number(e.target.value))}
                    className="setting-input"
                />
                <button onClick={handleSaveLimit} className="save-button">Save Rate Limit</button>
            </div>
        </div>
    );
};

export default Security;