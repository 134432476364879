// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './services/PrivateRoute'; // Import the PrivateRoute component
import Login from './pages/Login';
import Main from './pages/Main';
import MainApp from './pages/MainApp';
import AdminPage from './pages/AdminPage';
import ChatbotWidgetPage from './pages/ChatbotWidgetPage';
import ChangePassword from './pages/ChangePassword';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/main" element={<PrivateRoute><Main /></PrivateRoute>} />
        <Route path="/main/:aiAssistantId" element={<PrivateRoute><MainApp /></PrivateRoute>} />
        <Route path="/admin" element={<PrivateRoute><AdminPage /></PrivateRoute>} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/changepassword" element={<PrivateRoute><ChangePassword /></PrivateRoute>} />
      </Routes>
    </Router>
  );
}

export default App;
