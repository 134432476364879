import { useEffect, useState } from "react";
import User from "../entities/User";
import { createUser, getAllUsers, deleteUser } from "../services/adminApi";
import { useNavigate } from "react-router-dom";

const AdminPage = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        fetchUsers();
    }, []);

    const navigate = useNavigate();


    const fetchUsers = async () => {
        const response = await getAllUsers();
        setUsers(response.data);
    };

    const handleDelete = async (userId: string) => {
        await deleteUser(userId);
        fetchUsers();
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        await createUser(username, password, isAdmin);
        fetchUsers();
    };

    return (
        <div>
            <h1>Admin Page</h1>
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Username</th>
                        <th>Role</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.username}</td>
                            <td>{user.role}</td>
                            <td>
                                <button
                                    style={{ color: 'white', backgroundColor: 'red' }}
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this user? This action is IRREVERSIBLE!')) {
                                            handleDelete(user.id)
                                        }
                                    }}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={4}><hr /></td>
                    </tr>
                    <tr>
                        <td colSpan={4}>
                            <h2>Create New User</h2>
                            <form onSubmit={handleSubmit}>
                                <input type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder="Username" />
                                <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" />
                                <input type="checkbox" checked={isAdmin} onChange={e => setIsAdmin(e.target.checked)} /> Is Admin
                                <button type="submit">Create User</button>
                            </form>
                        </td>
                    </tr>
                    <button onClick={() => navigate('/main')} style={{backgroundColor: "red"}}>Back to Main Page</button>

                </tbody>
            </table>
        </div>
    );
}

export default AdminPage;