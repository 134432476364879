import React, { useContext, useState, useEffect } from 'react';
import { addAllowedOrigin, getAllowedOrigins } from '../../services/AIAssistantApi';
import { AuthContext } from '../../services/authContext';

const Notifications = ({aiAssistantId}: {aiAssistantId: string}) => {
    const { user, getCurrentAuthenticatedUser } = useContext(AuthContext);
    const [origin, setOrigin] = useState('');
    // const [notificationEmailAddress, setNotificationEmailAddress] = useState<string>('');

    // useEffect(() => {
    //     getAllowedOrigins(aiAssistantId).then((response) => {
    //         setAllowedOrigins(response.data);
    //     });
    // }, [aiAssistantId]);

    const handleSendMail = () => {

    };

 

    return (
        <div className="panel-container">
            <h2>Notifications</h2>
            <div className="setting-item">
                <h3>Notification E-mail Adress</h3>
                {user?.email}
                <input
                    type="text"
                    value={origin}
                    onChange={(e) => setOrigin(e.target.value)}
                    className="setting-input"
                />
                <button onClick={handleSendMail} className="save-button">Add Mail</button>
            </div>
        </div>
    );
};

export default Notifications;