import { config } from "../config";
import { get, post } from "./AxiosWrapper";

const AdminApiEndpoint = `${config.backendUrl}/user/admin`


export function getAllUsers() {
    return get(`${AdminApiEndpoint}/getallusers`, {});
}

export function createUser(username: string, password: string, isAdmin: boolean) {
    return post(`${AdminApiEndpoint}/createuser`, {
        username,
        password,
        isAdmin
    });
}

export function deleteUser(userId: string) {
    return post(`${AdminApiEndpoint}/deleteuser`, {userId});
}