import React, { useContext, useEffect, useRef, useState, ChangeEvent } from 'react';
import { ChatWidgetProps } from '../Settings';
import chatIcon from '../assets/dummy.jpeg'; // Adjust path as necessary
import { AuthContext } from '../../services/authContext';
import './ChatWidget.css';
import { getFirstMessageEnabled, getWidgetConfiguration, getWidgetLocation, getWidgetSampleQuestions } from '../../services/WidgetApi';
import {setAiAssistantSampleQuestions, setAiAssistantWidgetConfig, setAiAssistantWidgetLocation, setAiAssistantWidgetName, setAvatarURL, setAiAssistantFirstMessage, setAiAssistantFirstMessageEnabled } from '../../services/AIAssistantApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ChatColor {
    label: string;
    color: string;
}


const ChatWidget = ({ aiAssistantId }: { aiAssistantId: string }) => {
    const { user, getCurrentAuthenticatedUser, getUserAIAssistantWithId } = useContext(AuthContext);

    const [inputImageUrl, setImageUrl] = useState('');
    const [inputBotName, setBotName] = useState('');

    const [imageUrl, setImage] = useState('');
    const [botName, setName] = useState('');

    const [firstMessageEnabled, setFirstMessageEnabled] = useState(true);
    const [firstMessage, setFirstMessage] = useState('');

    const availablePositions: { [key: string]: { buttontop: string; iframetop: string; alignment: string; } } = {
        bottomright: { buttontop: "90%", iframetop: "17%", alignment:"end"},
        bottommiddle: { buttontop: "90%", iframetop: "17%", alignment:"center"},
        bottomleft: { buttontop: "90%", iframetop: "17%", alignment:"start"},
        topright: { buttontop: "5%", iframetop: "13%", alignment:"end"},
        topmiddle: { buttontop: "5%", iframetop: "13%", alignment:"center"},
        topleft: { buttontop: "5%", iframetop: "13%", alignment:"start"},
    };
    const [selectedPosition, setSelectedPosition] = useState(availablePositions.bottomright);


    const showToastRef = useRef(false);

    useEffect(() => {
        const fetchAIAssistant = async () => {
            const aiAssistant = await getUserAIAssistantWithId(aiAssistantId);
            setImage(aiAssistant.avatarUrl);
            setBotName(aiAssistant.widgetName ?? '');
            setName(aiAssistant.widgetName ?? '');
            setFirstMessage(aiAssistant.firstMessage ?? 'no message');
        };
        getWidgetConfiguration(aiAssistantId).then((response) => {
            Object.entries(response.data).forEach(([key, value]) => {
                setChatColors(prev => new Map(prev.set(key, { ...prev.get(key)!, color: value as string })));
            });
        });

        getWidgetSampleQuestions(aiAssistantId).then((response) => {
            setSampleQuestions(response.data);
        }).catch((error) => {
            console.log(error);
        });

        getWidgetLocation(aiAssistantId).then((response) => {
            console.log(response.data);
            console.log(availablePositions[response.data]);
            setSelectedPosition(response.data);
        }).catch((error) => {
            console.log(error);
        });

        getFirstMessageEnabled(aiAssistantId).then((response) => {
            console.log(response.data);
            setFirstMessageEnabled(response.data);
        })

        
        fetchAIAssistant();
    }, [aiAssistantId, getUserAIAssistantWithId]);

    useEffect(() => {},[botName, firstMessage, selectedPosition]);

    useEffect(() => {},[firstMessageEnabled]);
    


    const [chatColors, setChatColors] = useState<Map<string, ChatColor>>(new Map([
        ['headerbackground', { label: 'Header Background Color', color: '#000000' }],
        ['ChatBackground', { label: 'Chat Background Color', color: '#000000' }],
        ['botbubble', { label: 'Bot Bubble Color', color: '#000000' }],
        ['bottext', { label: 'Bot Text Color', color: '#000000' }],
        ['userbubble', { label: 'User Bubble Color', color: '#000000' }],
        ['usertext', { label: 'User Text Color', color: '#000000' }],
        ['inputbackground', { label: 'Input Background Color', color: '#000000' }],
        ['inputtext', { label: 'Input Text Color', color: '#000000' }],
        ['sampleQuestionTextColor', { label: 'Sample Question Text Color', color: '#000000' }],
        ['sampleQuestionBackground', { label: 'Sample Question Background Color', color: '#000000' }],
        ['HeaderTextColor', { label: 'Header Text Color', color: '#000000' }],
    ]));

    const [sampleQuestions, setSampleQuestions] = useState([""]);

    const dummyMessages = [
        { text: firstMessage, fromUser: false },
        { text: 'Hi!', fromUser: true },
        { text: 'How are you?', fromUser: false },
        { text: 'I am good, thank you!', fromUser: true },
    ];


    const messageStyle = (fromUser: boolean) => ({
        backgroundColor: fromUser ? chatColors.get('userbubble')?.color : chatColors.get('botbubble')?.color,
        color: fromUser ? chatColors.get('usertext')?.color : chatColors.get('bottext')?.color, // Assuming bot messages are in black
        alignSelf: fromUser ? 'flex-end' : 'flex-start',
    });

    const inputStyle = {
        backgroundColor: chatColors.get('inputbackground')?.color,
        color: chatColors.get('inputtext')?.color,
    };

    const sampleQuestionStyle = {
        backgroundColor: chatColors.get('sampleQuestionBackground')?.color,
        color: chatColors.get('sampleQuestionTextColor')?.color,
    };


    // const handleColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    //     setColor(event.target.value);
    // };

    const saveImageUrl = (url: string) => {
        console.log("Saving URL:", url);
        setImageUrl('');
        setImage(url);
        setAvatarURL(aiAssistantId, url);
    };

    const handleSaveBotName = async (botName: string) => {
        const response = await setAiAssistantWidgetName(aiAssistantId, botName);
        if (response.status === 200) {
            toast.success("Bot name saved", { position: 'bottom-right' });
        } else {
            toast.error("Error occured", { position: 'bottom-right'});
        }
    };

    const handleSaveFirstMessage = async (message: string) => {
        const response = await setAiAssistantFirstMessage(aiAssistantId, message);
        if (response.status === 200) {
            toast.success("First message saved", { position: 'bottom-right' });
        } else {
            toast.error("Error occured", { position: 'bottom-right'});
        }
    }

    const handleChatColorChange = (id: string) => (event: ChangeEvent<HTMLInputElement>) => {
        setChatColors(prev => new Map(prev.set(id, { ...prev.get(id)!, color: event.target.value })));
    };


    const handleSelectedPositionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedPosition(availablePositions[event.target.value]);
        console.log(selectedPosition)
    };

    // const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (event.target.files && event.target.files[0]) {
    //         const file = event.target.files[0];
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImage(reader.result as string);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };

    // const handleSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    //     setImageSize(Number(event.target.value));
    // };

    const saveChatWidget = async () => {
        const widgetConfigJson: { [key: string]: string } = {}; // Add index signature
        chatColors.forEach((colorData, key) => {
            widgetConfigJson[key] = colorData.color;
        })
        console.log(widgetConfigJson, selectedPosition);
        await setAiAssistantWidgetConfig(aiAssistantId, widgetConfigJson);
        await setAiAssistantWidgetLocation(aiAssistantId, selectedPosition);
        await setAiAssistantSampleQuestions(aiAssistantId, sampleQuestions);
        await setAiAssistantWidgetName(aiAssistantId, botName);
        await setAiAssistantFirstMessage(aiAssistantId, firstMessage);
        await setAiAssistantFirstMessageEnabled(aiAssistantId, firstMessageEnabled);
        alert("Widget configuration updated")
    };

    const handleQuestionChange = (index: any, event: any) => {
        const newSampleQuestions = [...sampleQuestions];
        newSampleQuestions[index] = event.target.value;
        setSampleQuestions(newSampleQuestions);
    };

    const handleAddQuestion = () => {
        setSampleQuestions([...sampleQuestions, ""]);
    };

    const handleDeleteQuestion = (index: any) => {
        const newSampleQuestions = [...sampleQuestions];
        newSampleQuestions.splice(index, 1);
        setSampleQuestions(newSampleQuestions);
    };

    return (
        <>
            <div className="widget-settings-container">
                <div className="left">
                    <h1>Widget</h1>
                    {/* <div className="widget-row color-picker">
                        <label className="widget-label">Pick a color:</label>
                        <div className="widget-input">
                            <input type="color" value={color} onChange={handleColorChange} />
                            <span className="color-hex">{color}</span>
                        </div>
                    </div> */}
                    {/* <div className="widget-row image-upload">
                        <label className="widget-label">Upload an image:</label>
                        <input type="file" accept="image/*" onChange={handleImageChange} className="widget-input-file" />
                    </div>
                    <div className="widget-row size-picker">
                        <label className="widget-label">Image size:</label>
                        <input type="number" value={imageSize} onChange={handleSizeChange} className="widget-input-number" min="10" max="200" />
                    </div> */}
                    {/* <div className="widget-row image-row">
                        <div className="image-preview" style={{
                            backgroundImage: `url(${image})`,
                            width: `${imageSize}px`,
                            height: `${imageSize}px`,
                        }} />
                    </div> */}
                    {/* Add more input boxes and color pickers as needed */}
                    <h2>Image URL</h2>
                    <input
                        type="text"
                        placeholder="Enter image link here"
                        value={inputImageUrl}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setImageUrl(e.target.value)} // Typing the event correctly
                    />
                    <button onClick={() => saveImageUrl(inputImageUrl)}>Save URL</button>
                    <h2>Bot Name</h2>
                    <input
                        type="text"
                        placeholder="Enter bot name here"
                        value={inputBotName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {setBotName(e.target.value); setName(e.target.value)}} // Typing the event correctly
                    />
                    <button onClick={() => handleSaveBotName(inputBotName)}>Save bot name</button>
                    <h2>First message</h2>

                    <h3>Enable first message</h3>
                    <input type="checkbox" checked={firstMessageEnabled} onChange={()=> setFirstMessageEnabled(!firstMessageEnabled)} />
                    <input
                        disabled={!firstMessageEnabled}
                        type="text"
                        placeholder="Enter bot first message here"
                        value={firstMessage}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {setFirstMessage(e.target.value)}} // Typing the event correctly
                    />
                    <button disabled={!firstMessageEnabled} onClick={()=>handleSaveFirstMessage(firstMessage)}>Save first message</button>
                    <h2>Chat Container</h2>
                    {Array.from(chatColors.entries()).map(([id, color], index) => (
                        <div className={`widget-row ${index % 2 === 0 ? 'dark' : 'darker'}`} key={id}>
                            <label className="widget-label">{color.label}:</label>
                            <input type="color" value={color.color} onChange={handleChatColorChange(id)} className="widget-input-color" />
                        </div>
                    ))}
                    <h3>Chat Layout</h3>
                    <select onChange={handleSelectedPositionChange}>
                        {Object.keys(availablePositions).map((key) => (
                            <option key={key} value={key}>
                                {key}
                            </option>
                        ))}
                    </select>
                    <button onClick={saveChatWidget}>Save Settings</button>
                    <h3>Sample Questions</h3>
                    <div className="set-sample-questions">
                        {sampleQuestions.map((question, index) => (
                            <div key={index} className="set-sample-question" style={{ display: 'flex', alignItems: 'center' }}>
                                <input type="text" value={question} onChange={(event) => handleQuestionChange(index, event)} style={{ marginRight: '10px' }} />
                                <button className='delete-question-button' onClick={() => handleDeleteQuestion(index)}>Delete</button>
                            </div>
                        ))}
                        <button onClick={handleAddQuestion}>Add Question</button>

                    </div>
                </div>
                <div className="center-line" />

                <div className="right">
                    <div className='widget-container' style={{ backgroundColor: chatColors.get('ChatBackground')?.color }}>
                        <div className='widget-header' style={{ backgroundColor: chatColors.get('headerbackground')?.color }}>
                            <div className='image-container'>
                                <img src={imageUrl} alt="Avatar" className="circular-image"/>
                            </div>
                            <div style={{ "marginBottom": "0px" }}>
                                <h3 style={{ color: chatColors.get('HeaderTextColor')?.color }}>{botName}</h3>
                            </div>
                        </div>
                        <div className="preview-chat-window">
                            <div className='preview-chat-hist'>
                                {dummyMessages.map((message, index) => (
                                    <div key={index} className='preview-sample-message' style={messageStyle(message.fromUser)}>
                                        {message.text}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='preview-input-container'>
                            <div className="preview-sample-questions">
                                {sampleQuestions.map((question, index) => (
                                    <button className="preview-sample-question" key={index}
                                        style={sampleQuestionStyle}
                                    >{question}</button>
                                ))}
                            </div>
                            <div className="input-area">
                                <input
                                    type="text"
                                    className="previev-message-input"
                                    placeholder="Message..."
                                    style={inputStyle}

                                />
                                {/* <button onClick={handleSendClick} style={buttonStyle}>
                        Send
                    </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer></ToastContainer>
        </>
    );
}

export default ChatWidget;