import axios, { AxiosResponse } from "axios";

export async function get<T = any>(url: string, params: object): Promise<AxiosResponse<T>> {
    return axios.get(url, {
        params: params,
        withCredentials: true
    })
}

export async function postWithReqParam<T = any>(url: string, params: object): Promise<AxiosResponse<T>> {
    return axios.post(url, {}, {
        params: params,
        withCredentials: true
    })
}

export async function post<T = any>(url: string, data: object): Promise<AxiosResponse<T>> {
    return axios.post(url, data, { withCredentials: true });
}
