import React, { useEffect, useState } from 'react';
import './DummyChatbot.css'; // Import your CSS file here
import ChatMessage from '../../entities/ChatMessage';

interface ChatbotProps {
    messages: ChatMessage[];
}

const DummyChatbot: React.FC<ChatbotProps> = ({ messages }) => {
    const [sourceText, setSourceText] = useState('');

    useEffect(() => {
        //console.log(messages);
        setSourceText('');
    }, [messages]);

    return (
        <div className='container'>
            <div className="chat-window">
                <div className='chat-hist'>
                    {messages.map((msg, index) => {
                        return (
                            <div key={index} className={`message ${msg.sender === 'AI' ? 'ai' : 'user'}`}>
                                {msg.message}
                                {msg.sender === 'AI' && index !== 0 && messages[index - 1].context &&
                                    <button className='show-source-button' onClick={() => {
                                        setSourceText(messages[index - 1].context ?? '');
                                    }}>Show source</button>
                                }
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className='source-window'>
                <h3>Sources</h3>
                <textarea readOnly value={sourceText} className='source-text' />
            </div>
        </div >
    );
};

export default DummyChatbot;