import axios from 'axios';
import { config } from '../config';

export async function getWidgetConfiguration(aiAssistantId: string) {
    return axios.get(`${config.backendUrl}/widget-api/getwidgetconfig`, { params: { aiAssistantId } });
}

export async function getWidgetSampleQuestions(aiAssistantId: string) {
    return axios.get(`${config.backendUrl}/widget-api/getwidgetsamplequestions`, { params: { aiAssistantId } });
}

export async function getWidgetAvatarUrl(aiAssistantId: string) {
    return axios.get(`${config.backendUrl}/widget-api/getwidgetavatarurl`, { params: { aiAssistantId } });
}

export async function getWidgetLocation(aiAssistantId: string) {
    return axios.get(`${config.backendUrl}/widget-api/getwidgetlocation`, { params: {aiAssistantId}});
}

export async function getWidgetName(aiAssistantId: string) {
    return axios.get(`${config.backendUrl}/widget-api/getwidgetname`, { params: { aiAssistantId } });
}

export async function getFirstMessageEnabled(aiAssistantId: string){
    return axios.get(`${config.backendUrl}/widget-api/getfirstmessageenabled`, { params: { aiAssistantId } });
}

export async function createNewWidgetChat(aiAssistantId: string, parentOrigin: string) {
    const response = await axios.post(`${config.backendUrl}/widget-api/newchat`, {aiAssistantId, parentOrigin});
    return response;
}

export async function sendMessageFromWidget(chatId: string, message: string, parentOrigin: string) {
    message = message.replaceAll("[\"']", "");
    const response = await axios.post(`${config.backendUrl}/widget-api/chat`, {chatId, message, parentOrigin});
    return response;
}
