// src/components/Chatbot.tsx
import React, { useState, useEffect, useRef, useContext } from 'react';
import './Chatbot.css'; // Ensure CSS is correctly imported
import { getNewChat, sendDebugMessage, updateAiAssistantChatbot } from '../services/ChatApi';
import { AuthContext } from '../services/authContext';
import { setAiAssistantStatus, updateAiAssistantContentRetriever } from '../services/AIAssistantApi';


const Chatbot = ({ aiAssistantId }: { aiAssistantId: string }) => {
  const { user, getUserAIAssistantWithId, getCurrentAuthenticatedUser } = useContext(AuthContext);

  const [isTyping, setIsTyping] = useState(false);
  interface IMessage {
    id: number;
    text: string | JSX.Element;
    sender: string;
  }
  const [messages, setMessages] = useState<IMessage[]>([{ id: 1, text: "Welcome to the Chatbot!", sender: "bot" }]);
  const [inputValue, setInputValue] = useState('');
  const [temperature, setTemperature] = useState(0); // Initialize with default value
  const [minScore, setMinScore] = useState(0);
  const [maxResults, setMaxResults] = useState(0);
  const [showSource, setShowSource] = useState(false);
  const [isBotActive, setIsBotActive] = useState(false);
  const [chatId, setChatId] = useState<string | null>(null);
  const [selectedModel, setSelectedModel] = useState(''); // Initialize with default value
  const [availableModels, setAvailableModels] = useState<string[]>([]); // Initialize with default value
  const [aiAssistantName, setAiAssistantName] = useState(''); // Initialize with default value
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const fetchAIAssistant = async () => {
      const aiAssistant = await getUserAIAssistantWithId(aiAssistantId);
      setTemperature(aiAssistant.temperature);
      setSelectedModel(aiAssistant.selectedModelName);
      setMinScore(aiAssistant.minScore);
      setMaxResults(aiAssistant.maxResults);
      setAvailableModels([...aiAssistant.availableModelNames, aiAssistant.availableModelNames[0]]);
      setAiAssistantName(aiAssistant.aiAssistantName || aiAssistantId);
      setIsBotActive(aiAssistant.isActive);
    };

    fetchAIAssistant();
  }, [aiAssistantId, getUserAIAssistantWithId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleNewChat = () => {
    if (aiAssistantId) {
      getNewChat(aiAssistantId).then((response) => {
        setChatId(response.data);
      });
    }

  };


  const handleSave = async () => {
    await updateAiAssistantContentRetriever(aiAssistantId, minScore, maxResults);
    await setAiAssistantStatus(aiAssistantId, isBotActive);
    updateAiAssistantChatbot(aiAssistantId, temperature, selectedModel)
      .then((response) => {
        getCurrentAuthenticatedUser(); // Update the user context after saving the chatbot settings
        alert('AI assistant chatbot settings saved');
      })
      .catch((error) => {
        console.error('Error updating AI assistant chatbot', error);

      });
  };

  const handleSendMessage = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const userMessage = { id: messages.length + 1, text: inputValue, sender: "user" };
      const botMessagePlaceholder = { id: messages.length + 2, text: 'Typing ...', sender: "bot" }; // Placeholder for bot's response
      setMessages([...messages, userMessage, botMessagePlaceholder]);
      setInputValue(''); // Clear input after sending
      setIsTyping(true); // Show typing indicator

      sendDebugMessage(chatId as string, inputValue).then((response) => {
        setIsTyping(false); // Hide typing indicator
        animateMessage(response.data, botMessagePlaceholder.id); // Pass the ID of the bot's placeholder message
      }).catch((error) => {
        if (error.response && error.response.status === 429) {
          setIsTyping(false); // Hide typing indicator
          console.log("Too many requests");
          animateMessage("Too many requests...", botMessagePlaceholder.id);
        } else {
          // Handle other errors
          console.log(error);
        }
      });
    }
  };


  interface TextSegment {
    type: 'text' | 'link';
    content: string;
    url?: string;
  }

  const preprocessText = (text: string): TextSegment[] => {
    const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
    let segments: TextSegment[] = [];
    let lastIdx = 0;
    let match;

    while ((match = regex.exec(text)) !== null) {
      // Add text before the link
      if (match.index > lastIdx) {
        segments.push({ type: 'text', content: text.substring(lastIdx, match.index) });
      }
      // Add the link
      segments.push({ type: 'link', content: match[1], url: match[2] });
      // Update the last index past this match
      lastIdx = match.index + match[0].length;
    }

    // Add any remaining text after the last link
    if (lastIdx < text.length) {
      segments.push({ type: 'text', content: text.substring(lastIdx) });
    }

    return segments;
  };

  const animateMessage = (fullText: string, messageId: number) => {
    const segments = preprocessText(fullText);
    let displayContent: React.ReactNode[] = [];
    let segmentIndex = 0;
    let charIndex = 0;

    const intervalId = setInterval(() => {
      if (segmentIndex < segments.length) {
        const segment = segments[segmentIndex];
        if (segment.type === 'text') {
          if (charIndex < segment.content.length) {
            displayContent.push(segment.content[charIndex++]);
          } else {
            segmentIndex++;
            charIndex = 0;
          }
        } else if (segment.type === 'link') {
          displayContent.push(<a href={segment.url} target="_blank" rel="noopener noreferrer">{segment.content}</a>);
          segmentIndex++;
        }

        // Update the message in state
        setMessages(prevMessages => prevMessages.map(msg =>
          msg.id === messageId ? { ...msg, text: <>{displayContent}</> } : msg
        ));
      } else {
        clearInterval(intervalId);
      }
    }, 10); // Adjust the interval time as necessary for desired speed
  };

  const toggleSourcePopup = () => {
    setShowSource(!showSource);
  };

  const onlineStyle = { color: 'green', paddingTop: "10px"};
  const offlineStyle = { color: 'red', paddingTop: "10px"};

  return (
    <div className="chatbot-container">
      <><div className="model-info">
        <h3>Model Name</h3>
        <p>{(aiAssistantName)}</p>

        <div className="set-model">
          <h3>AI Model</h3>
          <select
            value={selectedModel}
            onChange={(e) => setSelectedModel(e.target.value)}
            className="model-select"
          >
            {availableModels.map((model, index) => (
              <option key={index} value={model}>{"Model " + (index + 1)}</option>
            ))}

          </select>
          <h3>Chatbot Status
          </h3>
          <label className="switch">
            <input type="checkbox" checked={isBotActive} onChange={(e) => setIsBotActive(e.target.checked)} />
            <span className="slider"></span>
            <div style={isBotActive ? onlineStyle : offlineStyle}>
              {isBotActive ? ' online' : ' offline'}
            </div>
          </label>
          <h3>Temperature</h3>
          <input
            type="range"
            min="0"
            max="1"
            step="0.05"
            value={temperature}
            onChange={(e) => setTemperature(parseFloat(e.target.value))} />
          <p>{temperature.toFixed(2)}</p>
          <h3>Min Score</h3>
          <input
            type="range"
            min="0"
            max="1"
            step="0.05"
            value={minScore}
            onChange={(e) => setMinScore(parseFloat(e.target.value))} />
          <p>{minScore.toFixed(2)}</p>
          <h3>Max Results</h3>
          <input
            type="range"
            min="0"
            max="10"
            step="1"
            value={maxResults}
            onChange={(e) => setMaxResults(parseInt(e.target.value))} />
          <p>{maxResults.toFixed()}</p>
          <button onClick={handleSave}>Save</button>
        </div>

        <p><button onClick={handleNewChat}>New Chat</button></p>
      </div></>

      {chatId && (<>
        <div className="cb-chat-window">
          <div className='cb-chat-hist'>
            {messages.map((msg) => (
              <div key={msg.id} className={`message ${msg.sender}`}>{msg.text}</div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="input-area">
            <input
              type="text"
              className="message-input"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={handleSendMessage}
              placeholder="Type your message here..." />
          </div>
          <button className="show-source-btn" onClick={toggleSourcePopup}>Show Source</button>

          {showSource && (
            <div className="source-popup">
              <div className="source-content">
                <span className="close-btn" onClick={toggleSourcePopup}>&times;</span>
                <p>This is the source information for the chatbot.</p>
                {/* Insert your specific text or content here */}
              </div>
            </div>
          )}
        </div></>)}

    </div>
  );
};

export default Chatbot;
