import React, { useState, useEffect, useContext } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import './ViewStatistics.css'

import { Circles } from 'react-loader-spinner';  // Importing the specific loader component

// Import data, assuming the JSON file is fetched or updated dynamically
import data from './dummyData.json';
import { AuthContext } from '../../services/authContext';
import { addReportKeyword, removeReportKeyword } from '../../services/AIAssistantApi';
import { getChatReport } from '../../services/ChatApi';

interface KeywordData {
    keyword: string;
    count: number;
}

const ViewStatistics = ({ aiAssistantId }: { aiAssistantId: string }) => {
    const { user, getCurrentAuthenticatedUser, getUserAIAssistantWithId } = useContext(AuthContext);

    const [keywords, setKeywords] = useState<KeywordData[]>([]);
    const [newKeyword, setNewKeyword] = useState('');
    // State to hold chart data
    const [barChartData, setBarChartData] = useState<ChartData<'bar'>>({ datasets: [] });
    const [pieChartData, setPieChartData] = useState<ChartData<'pie'>>({ datasets: [] });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const fetchAIAssistant = async () => {
            const aiAssistant = await getUserAIAssistantWithId(aiAssistantId);
            console.log(aiAssistant);
        };
        fetchAIAssistant();
        const startDate = new Date(new Date().setDate(new Date().getDate() - 7));
        startDate.setHours(0, 0, 0, 0); // Set the time to the start of yesterday
        const endDate = new Date(new Date().setDate(new Date().getDate() + 1)); // Today's date
        endDate.setHours(23, 59, 59, 999); // Set the time to the end of today

        const startDateISOString = startDate.toISOString();
        const endDateISOString = endDate.toISOString();
        getChatReport(aiAssistantId, startDateISOString, endDateISOString).then(res => {
            console.log(res.data);
            // Transform res.data into items format
            const transformedData = Object.keys(res.data).map(keyword => ({
                keyword: keyword,
                count: res.data[keyword]
            }));

            // Sort data by count in descending order
            const sortedData = transformedData.sort((a, b) => b.count - a.count);

            // Load and sort data into state
            setKeywords(sortedData);
        }).catch(err => {
            console.error(err);
        })

        setLoading(false);
        // Load and sort data into state
        // const sortedData = [...data.items].sort((a, b) => b.count - a.count);
        // console.log(data.items);
        // setKeywords(sortedData);
    }, []);

    useEffect(() => {
        // Update bar chart data whenever keywords change
        const barData: ChartData<'bar'> = {
            labels: keywords.map(item => item.keyword),
            datasets: [{
                label: 'Keyword Counts',
                data: keywords.map(item => item.count),
                backgroundColor: keywords.map((_, index) => `rgba(${255 - index * 40}, ${99 + index * 30}, ${132 + index * 20}, 0.5)`),
                borderColor: keywords.map((_, index) => `rgba(${255 - index * 40}, ${99 + index * 30}, ${132 + index * 20}, 1)`),
                borderWidth: 1
            }]
        };
        setBarChartData(barData);

        // Update pie chart data similarly
        const pieData: ChartData<'pie'> = {
            labels: keywords.map(item => item.keyword),
            datasets: [{
                data: keywords.map(item => item.count),
                backgroundColor: keywords.map((_, index) => `rgba(${255 - index * 50}, ${99 + index * 50}, ${132 + index * 50}, 0.5)`),
                hoverBackgroundColor: keywords.map((_, index) => `rgba(${255 - index * 50}, ${99 + index * 50}, ${132 + index * 50}, 0.6)`)
            }]
        };
        setPieChartData(pieData);

    }, [keywords]);

    const addKeyWord = () => {
        addReportKeyword(aiAssistantId, newKeyword).then(res => {
            alert(`New Keyword: ${res.data}`);
            setNewKeyword('');
            setLoading(true);
            const fetchAIAssistant = async () => {
                const aiAssistant = await getUserAIAssistantWithId(aiAssistantId);
                console.log(aiAssistant);
            };
            fetchAIAssistant();
            const startDate = new Date(new Date().setDate(new Date().getDate() - 30));
            startDate.setHours(0, 0, 0, 0); // Set the time to the start of yesterday
            const endDate = new Date(); // Today's date
            endDate.setHours(23, 59, 59, 999); // Set the time to the end of today

            const startDateISOString = startDate.toISOString();
            const endDateISOString = endDate.toISOString();
            getChatReport(aiAssistantId, startDateISOString, endDateISOString).then(res => {
                console.log(res.data);
                // Transform res.data into items format
                const transformedData = Object.keys(res.data).map(keyword => ({
                    keyword: keyword,
                    count: res.data[keyword]
                }));

                // Sort data by count in descending order
                const sortedData = transformedData.sort((a, b) => b.count - a.count);

                // Load and sort data into state
                setKeywords(sortedData);
            }).catch(err => {
                console.error(err);
            })

            setLoading(false);

        }).catch(err => {
            console.error(err);
        })
    };

    const handleRemoveKeyword = (keyword: string) => {
        removeReportKeyword(aiAssistantId, keyword).then(res => {
            alert("Removed keyword");

            setLoading(true);
            const fetchAIAssistant = async () => {
                const aiAssistant = await getUserAIAssistantWithId(aiAssistantId);
                console.log(aiAssistant);
            };
            fetchAIAssistant();
            const startDate = new Date(new Date().setDate(new Date().getDate() - 30));
            startDate.setHours(0, 0, 0, 0); // Set the time to the start of yesterday
            const endDate = new Date(); // Today's date
            endDate.setHours(23, 59, 59, 999); // Set the time to the end of today

            const startDateISOString = startDate.toISOString();
            const endDateISOString = endDate.toISOString();
            getChatReport(aiAssistantId, startDateISOString, endDateISOString).then(res => {
                console.log(res.data);
                // Transform res.data into items format
                const transformedData = Object.keys(res.data).map(keyword => ({
                    keyword: keyword,
                    count: res.data[keyword]
                }));

                // Sort data by count in descending order
                const sortedData = transformedData.sort((a, b) => b.count - a.count);

                // Load and sort data into state
                setKeywords(sortedData);
            }).catch(err => {
                console.error(err);
            })

            setLoading(false);
        }).catch(err => {
            console.error(err);
        })
    }

    if (loading) {
        return (
            <div className="loader-container">
                <Circles color="#00BFFF" height={80} width={80} ariaLabel='loading' />
            </div>
        );
    }
    return (
        <div>
            <h2>Keyword Statistics</h2>
            <div>
                <input
                    type="text"
                    value={newKeyword}
                    onChange={(e) => setNewKeyword(e.target.value)}
                    placeholder="Add new keyword"
                />
                {/* Dummy button: does nothing on click */}
                <button onClick={addKeyWord}>Add Keyword</button>
            </div>
            <table className="table-keywords">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Keyword</th>
                        <th>Count</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {keywords.map((item, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="keyword-cell">{item.keyword}</td>
                            <td>{item.count}</td>
                            <td>
                                <button onClick={() => handleRemoveKeyword(item.keyword)} className="remove-btn">
                                    Remove
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>


            <div>
                <h3>Bar Chart</h3>
                <Bar data={barChartData} />
            </div>
            <div>
                <h3>Pie Chart</h3>
                <Pie data={pieChartData} />
            </div>
        </div>
    );
};

export default ViewStatistics;
